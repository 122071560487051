import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import RichText from '@core/RichText';
import { grid, layoutWrapper } from '@mixins';
import mq from '@mq';

const DatenschutzPage = ({
  data: {
    contentfulDatenschutzPage: { text, title },
  },
}) => (
  <Main>
    <h1>{title}</h1>
    <Text
      text={{
        raw: text.raw.replace(' und Roland F. Bühler', ''),
      }}
    />
  </Main>
);

const Main = styled.main`
  ${grid};
  ${layoutWrapper};

  background-color: var(--color-black-olive);
  color: white;

  padding-top: 14.0625rem;
  padding-bottom: 7rem;

  ${mq.small} {
    padding-top: 15.1875rem;
  }

  h1 {
    font: var(--font-section-title);
    grid-column: 1 / -1;
    margin-bottom: 1em;

    ${mq.small} {
      grid-column: 2 / -2;
    }
  }

  h2 {
    font: var(--font-section-title);
  }
`;

const Text = styled(RichText)`
  grid-column: 1 / -1;

  ${mq.small} {
    grid-column: 2 / -2;
  }
`;

export const DatenschutzPageQuery = graphql`
  query DatenschutzPageQuery {
    contentfulDatenschutzPage {
      title
      text {
        raw
      }
    }
  }
`;

export default DatenschutzPage;
